import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from '../Components/SliderDisclaimer';

const Disclaimer = () => {
  const [disclaimerSections, setDisclaimerSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://back.zubilaw.com/disclaimer/list");
      if (response.data.success) {
        setDisclaimerSections(response.data.data);
      } else {
        console.error("Failed to fetch disclaimers:", response.data.message);
        setError('Failed to fetch disclaimer content');
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching disclaimer content. Please try again later.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const enhanceHtml = (html) => {
    if (typeof html !== 'string') {
      return html;
    }
    return html.replace(/<h1(.*?)>/g, '<h1 class="text-4xl"$1>');
  };

  return (
    <>
      <div className="container bg-white items-center mx-auto xl:max-w-7xl w-full px-5 md:px-0 xl:px-0 pb-20">
        <Slider />
        <div className="flex flex-col items-start mb-10 px-0 md:px-10 xl:px-24 mt-20">
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : (
            disclaimerSections.map((disclaimer, index) => (
              <div
                key={index}
                className="mb-5 text-base md:text-lg text-black"
                style={{ fontFamily: 'Arial', textAlign: 'justify', textJustify: 'inter-word' }}
                dangerouslySetInnerHTML={{ __html: enhanceHtml(disclaimer.title) }}
              />
            ))
          )}
        </div>
        <div className="w-full border-t border-gray-300 mb-5 mx-auto" style={{ maxWidth: '50%' }}></div>
      </div>
    </>
  );
};

export default Disclaimer;
