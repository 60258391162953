import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from '../Components/SliderAssociatedOffices';

import locationIcon from '../Images/drop.png';
import mailboxIcon from '../Images/mail.png';
import phoneIcon from '../Images/call.png';
import faxIcon from '../Images/fax.png';
import emailIcon from '../Images/email.png';
import websiteIcon from '../Images/web.png';

const AssociatedOffices = () => {
  const fixedHeight = '450px';
  const [officeLocation, setOfficeLocation] = useState('');
  const [officeData, setOfficeData] = useState(null);
  const [images, setImages] = useState([]); 

  useEffect(() => {
    const fetchSliderDetails = async () => {
      try {
        const response = await axios.get('https://back.zubilaw.com/slider/list');
        if (response.data && response.data.data && response.data.data.length > 0) {
          const sliderImages = response.data.data.map(slider => slider.slider || 'fallback-image-path.jpg');
          setImages(sliderImages);
        } else {
          console.error('Unexpected response structure or no slider details:', response.data);
        }
      } catch (error) {
        console.error('Failed to fetch slider details', error);
      }
    };
  
    fetchSliderDetails();
  }, []);

  const fetchOfficeData = async () => {
    try {
      const response = await axios.get('https://back.zubilaw.com/officesbutton/list');
      setOfficeData(response.data);
      if (response.data && response.data.data && response.data.data.length > 0) {
        setOfficeLocation(response.data.data[0].button);
      }
    } catch (error) {
      console.error('Error fetching office data:', error);
    }
  };

  useEffect(() => {
    fetchOfficeData();
  }, []);


  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
     
     <div className="container bg-[#777777] items-center   mx-auto xl:max-w-7xl w-full px-5 md:px-0 xl:px-0 pb-20" style={{ backgroundColor: 'white' }}>
     <Slider />    
        <div className="flex justify-center gap-10 mb-10 mt-20">
          {/* Render location buttons */}
          {officeData &&
            officeData.data &&
            officeData.data.length > 0 &&
            officeData.data.map((office, index) => (
              <button
                key={index}
                style={{
                  backgroundColor: officeLocation === office.button ? '#840626' : 'transparent',
                  color: officeLocation === office.button ? 'white' : '#840626',
                  border: '1px solid #840626',
                  fontFamily: 'Arial'
                }}
                className={`px-4 py-2 rounded`}
                onClick={() => setOfficeLocation(office.button)}
              >
                {office.button}
              </button>
            ))}
        </div>
        {officeData &&
          officeData.data &&
          officeData.data.length > 0 &&
          officeData.data.map((office, index) =>
            officeLocation === office.button ? (
              <div key={index} className="flex flex-col xl:flex-row justify-center gap-8 mx-auto ml-7 md:ml-60 xl:ml-0">
                <div className="w-full max-w-xs" style={{ height: fixedHeight }}>
                  <div className="h-full border p-4 flex flex-col justify-center">
                    <h2 className="text-md font-semibold mb-2" style={{ fontFamily: 'Arial' }}>Contact info.</h2>
                    <div>
                      <div style={{ marginBottom: '16px', fontFamily: 'Arial' }}>
                        <strong>{office.button}</strong>
                      </div>
                      <p style={{ marginBottom: '12px', fontFamily: 'Arial' }}>{office.name}</p>
                      <div className="flex items-start mb-2" style={{ gap: '8px' }}>
                        <img src={locationIcon} alt="Location Icon" style={{ width: '24px', height: '24px', marginTop: '6px' }} />
                        <a href={office.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', fontFamily: 'Arial' }}>
                          <p >{office.address}</p>
                        </a>
                      </div>
                      <div className="flex items-start mb-2" style={{ gap: '8px' }}>
                        <img src={mailboxIcon} alt="Mailbox" style={{ width: '24px', height: '24px' }} />
                        <p style={{ marginBottom: '12px', fontFamily: 'Arial' }}>{office.mail}</p>
                      </div>
                      <div className="flex items-center mb-2">
                        <img src={phoneIcon} alt="Phone" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                        <a href={`tel:${office.mobile}`} style={{ textDecoration: 'none', fontFamily: 'Arial' }}>
                          <p>{office.mobile}</p>
                        </a>
                      </div>
                      <div className="flex items-center mb-2">
                        <img src={faxIcon} alt="Fax" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                        <p style={{ fontFamily: 'Arial' }}>{office.fax}</p>
                      </div>
                      <div className="flex items-center mb-2">
                        <img src={emailIcon} alt="Email" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                        <a href={`mailto:${office.email}`} style={{ textDecoration: 'none', fontFamily: 'Arial' }}>
                          <p>{office.email}</p>
                        </a>
                      </div>
                      <div className="flex items-center mb-2">
                        <img src={websiteIcon} alt="Website" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                        <a href="https://zubipartners.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', fontFamily: 'Arial' }}>
                          <p>{office.website}</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full max-w-xs" style={{ height: fixedHeight }}>
                  <iframe
                    src={office.map}
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
                <div className="w-full max-w-xs" style={{ height: fixedHeight }}>
                  <img src={`https://back.zubilaw.com/${office.image}`} alt="Office" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </div>
              </div>
            ) : null
          )}


<div className="w-full border-t border-gray-300 mb-5 mx-auto mt-20" style={{ maxWidth: '50%' }}></div>
      </div>
    
    </>
  );
};

export default AssociatedOffices;
