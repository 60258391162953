import React, { useState, useEffect } from 'react';
import axios from 'axios';




const Partners = () => {


const [title, setTitle] = useState('');
const [paragraph, setParagraph] = useState('');
const [images, setImages] = useState([]);




useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get('https://back.zubilaw.com/partnerstitle/list');
      if (response.data.success && response.data.data.length > 0) {
        // Access the first item in the array
        setTitle(response.data.data[0].title);
        setParagraph(response.data.data[0].paragraph);
      } else {
        console.log('Data not found');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);


useEffect(() => {
  const fetchImages = async () => {
    try {
      const response = await axios.get('https://back.zubilaw.com/projectsimages/list');
      if (response.data.success && response.data.data) {
        setImages(response.data.data); // Set the images state with the fetched data
      } else {
        console.log('No images found');
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  fetchImages();
}, []);




  return (
    <div className=" mx-auto px-4 md:px-0  py-28 mx-auto xl:max-w-7xl pt-32"style={{ backgroundColor: 'white' }}>
    <div className="flex flex-col sm:flex-row items-center justify-center space-x-6 sm:space-x-6 md:space-x-12 xl:space-x-52 ">

      <div className="flex items-center mb-8 sm:mb-0 ml-5 ">
      <h2 className="text-2xl font-semibold text-[#972a36]" style={{ fontFamily: 'Arial' }}>
  {title} <br className="hidden sm:block" /> {paragraph}
</h2>

        <span className="bg-[#777777] w-0.5 h-14 inline-block align-middle ml-3"></span>
      </div>

        <div className="flex flex-col md:flex-row items-center justify-center my-10 md:my-0 xl:my-0 space-x-2 sm:space-x-4 md:space-x-2 xl:space-x-6 space-y-14 md:space-y-0 xl:space-y-0  ">
        {images.map((image, index) => (
    <img key={index} src={`https://back.zubilaw.com/${image.path}`} alt={`Award ${index + 1}`} className="h-24 md:w-24 my-2" />
  ))}
</div>
      
    </div>
</div>

);
};

export default Partners;