import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from '../Components/SliderNews';
import { Link } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';

const styles = `
  @keyframes scaleUp {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.05);
    }
  }

  .hover-card {
    transition: transform 0.3s ease;
  }

  .hover-card:hover {
    animation: scaleUp 0.3s ease forwards;
  }
`;

// Inject keyframes into the document
const injectStyles = () => {
  const styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);
};

const NewsPage = () => {
  const [featureSections, setFeatureSections] = useState([]);
  const [images, setImages] = useState([]); 

  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
    injectStyles();
  }, []);
  
  useEffect(() => {
    const fetchSliderDetails = async () => {
      try {
        const response = await axios.get('https://back.zubilaw.com/slider/list');
        if (response.data && response.data.data && response.data.data.length > 0) {
          const sliderImages = response.data.data.map(slider => slider.slider || 'fallback-image-path.jpg');
          setImages(sliderImages);
        } else {
          console.error('Unexpected response structure or no slider details:', response.data);
        }
      } catch (error) {
        console.error('Failed to fetch slider details', error);
      }
    };
  
    fetchSliderDetails();
  }, []);
  
  useEffect(() => {
    const fetchFeatureSections = async () => {
      try {
        const response = await axios.get('https://back.zubilaw.com/featureSection/list'); // Update with your actual endpoint
        setFeatureSections(response.data.data); // Assuming the data is structured this way
      } catch (error) {
        console.error("Error fetching feature sections:", error);
      }
    };

    fetchFeatureSections();
  }, []);
  
  return (
    <>
      <div className="container bg-[#777777] items-center mx-auto xl:max-w-7xl w-full px-5 md:px-0 xl:px-0 pb-20" style={{ backgroundColor: 'white' }}>
        <Slider News="NEWS"/>
        <div className="flex justify-center items-center flex-col mb-8 px-0 xl:px-0 mt-20">
          <div className="max-w-5xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 w-full px-8 xl:px-4 gap-10">
            {featureSections.map((section, index) => (
              <div className="w-64 hover-card " style={{ cursor: 'pointer', fontFamily: 'Arial' }} key={index}>
<div className="rounded-lg overflow-hidden h-64 shadow-lg flex items-center justify-center mb-5" style={{ borderRadius: '2.5rem' }}>
  <img src={`https://back.zubilaw.com/${section.image}`} alt={section.title} className="max-w-full max-h-full" style={{ borderRadius: '2.5rem' }} />
</div>


                <h1 className="font-semibold text-sm mb-2 p-2 text-md md:text-lg text-center" style={{ fontFamily: 'Arial' }}>{section.title}</h1>
                <Link to={`/news/${section.id}`} state={{ imageSrc: section.image, title: section.title, description: section.paragraph }} className="no-underline text-[#972a36] block text-center" style={{ fontFamily: 'Arial' }}>
                  Read More »
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full border-t border-gray-300 mb-5 mx-auto" style={{ maxWidth: '50%' }}></div>
      </div>
    </>
  );
};

export default NewsPage;
