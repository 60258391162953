import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Slider from '../Components/Slider';

import errorImage from '../Images/404-error-with-landscape-concept-illustration_114360-7898.avif';

function NavbarPage() {
  const [content, setContent] = useState('');
  const { link } = useParams(); // Extracts 'link' from the URL parameters

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(`https://back.zubilaw.com/navbar/${link}`);
        if (response.data && response.data.data && response.data.data.content) {
          // Successfully retrieved the content
          setContent(response.data.data.content);
        } else {
          // The response structure is not as expected
          console.error('Unexpected response structure:', response);
          setContent('The content format is not as expected. Please contact the administrator.');
        }
      } catch (error) {
        console.error(`Error fetching content: ${error}`);
        // Display different messages based on the error type
        setContent(
          error.response && error.response.status === 404
            ? `<div class="flex flex-col justify-center items-center px-5 xl:px-60 ">
                <img src="${errorImage}" alt="Error Image" />
                <p>Content not found. Please check the link.</p>
              </div>`
            : '<p>Error loading content. Please try again later.</p>'
        );
      }
    };

    fetchContent();
  }, [link]); // Re-run the effect if 'link' changes

  return (
    <div className="container bg-[#777777] items-center   mx-auto xl:max-w-7xl w-full px-5 md:px-0 xl:px-0 pb-10 mb-20" style={{ backgroundColor: 'white' }}>
    <Slider />
    <div className="flex flex-col lg:flex-row justify-between items-center py-2  mx-auto xl:max-w-7xl w-full px-5 md:px-10 xl:px-20 pt-20   pb-20 bg-white mt-10 mb-12" >
     
    <div dangerouslySetInnerHTML={{ __html: content }} /> 
    </div>
    </div>

  );
}

export default NavbarPage;
