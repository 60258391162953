import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Slider1 from '../Components/SliderLawyers'; // Ensure the correct path to the slider component

const renderParagraph = (para) => {
  // Remove the surrounding <ul></ul> tags
  const cleanedContent = para.replace(/<\/?ul[^>]*>/g, '');
  // Split the cleaned content into individual items
  const items = cleanedContent.split('</li>').map((item, index) => {
    // Remove opening <li> tag and any extra spaces
    const cleanedItem = item.replace('<li>', '').trim();
    // Render each item as a div with bullet point if not empty
    return cleanedItem ? <div key={index} className="mb-2">• {cleanedItem}</div> : null;
  });

  // Return the list of items wrapped in a div
  return <div className="mb-4 text-left ml-5 mt-5">{items}</div>;
};

const enhanceHtml = (html) => {
  return html
    .replace(/<h1>/g, '<h1 class="text-4xl font-bold">')
    .replace(/<li>/g, '<li class="list-disc list-inside">');
    
};



const Card = ({ image, headtitle, title, paragraph, onClick }) => (
  <div 
    className="border rounded-lg overflow-hidden relative mb-8 w-full cursor-pointer flex"
    onClick={onClick}
  >
    <div className="flex-shrink-0">
      <img src={image} alt={headtitle} className="w-40 h-46 object-cover m-4" />
    </div>
    <div className="p-4 flex flex-col justify-between flex-grow">
      <h1 className="font-bold text-lg mb-1" style={{ fontFamily: 'Arial' }}>{headtitle}</h1>
      <h2 className="text-sm mb-1" style={{ fontFamily: 'Arial' }}>{title}</h2>
      <p className="text-sm mb-1" dangerouslySetInnerHTML={{ __html: enhanceHtml(paragraph) }}></p>
      <div className="text-right">
      <button className="text-[#972a36] text-sm">See my full profile</button>
      </div>
    </div>
  </div>
);

const Associates = () => {
  const [departmentsData, setDepartmentsData] = useState([]);
  const [images, setImages] = useState([]);
  const [texts, setTexts] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTexts = async () => {
      try {
        const response = await axios.get('https://back.zubilaw.com/SliderAssociates/listTexts');
        setTexts(response.data.data || []);
      } catch (error) {
        console.error('Failed to fetch texts', error);
      }
    };
  
    fetchTexts();
  }, []);
  


  useEffect(() => {
    const fetchSliderDetails = async () => {
      try {
        const response = await axios.get('https://back.zubilaw.com/slider/list');
        if (response.data && response.data.data && response.data.data.length > 0) {
          const sliderImages = response.data.data.map(slider => slider.slider || 'fallback-image-path.jpg');
          setImages(sliderImages);
        } else {
          console.error('Unexpected response structure or no slider details:', response.data);
        }
      } catch (error) {
        console.error('Failed to fetch slider details', error);
      }
    };
  
    fetchSliderDetails();
  }, []);

  useEffect(() => {
    const fetchDepartmentsData = async () => {
      try {
        const response = await axios.get('https://back.zubilaw.com/AssociatesRouters/list');
        setDepartmentsData(response.data.data);
      } catch (error) {
        console.error("Failed to fetch departments data:", error);
      }
    };

    fetchDepartmentsData();
  }, []);

  const handleCardClick = (item) => {
    navigate('/Detailed-Associates', {
      state: {
        image: `https://back.zubilaw.com/${item.image}`,
        headtitle: item.headtitle,
        title: item.title,
        contact: item.contact,
        phone: item.phone,
        email: item.email,
        paragraph: item.paragraph || 'No description available', // Provide a default value
        content: item.content,
        lastUpdate: item.lastUpdate,
      }
    });
  };
  

  const groupedByDepartment = departmentsData.reduce((acc, item) => {
    const department = item.department || 'Unknown Department';
    if (!acc[department]) {
      acc[department] = [];
    }
    acc[department].push(item);
    return acc;
  }, {});

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  return (
    <div className="container bg-[#777777] items-center mx-auto xl:max-w-7xl w-full px-5 md:px-0 xl:px-0 pb-20" style={{ backgroundColor: 'white' }}>
      <Slider1 images={images} Departments="Associates"/>
      <div className="w-full mt-20">
      {texts.map((text, index) => (
        <h2 className="text-3xl font-semibold mb-2 text-[#972a36] ml-7" key={index} dangerouslySetInnerHTML={{ __html: text.text }}></h2>
))}
 <div className="bg-[#777777] h-0.5 w-16  mt-2 mb-6 ml-7" ></div>
 </div>
      {Object.keys(groupedByDepartment).map((department, index) => (
        <div key={index} className="w-full mt-10">


          {groupedByDepartment[department].map((item, itemIndex) => (
            <div key={itemIndex} className="w-full">
        <Card 
  image={`https://back.zubilaw.com/${item.image}`}
  headtitle={item.headtitle}
  title={item.title}
  paragraph={item.paragraph}  // Ensure paragraph is passed here
  onClick={() => handleCardClick(item)}
/>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Associates;
