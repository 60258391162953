import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { Navigation, Autoplay } from 'swiper/modules';

const SliderDetailedAssociates = ({ Departments }) => {
  const [dynamicHeight, setDynamicHeight] = useState('350px');
  const [images, setImages] = useState([]);
  const [texts, setTexts] = useState([]);
  const sliderRef = useRef(null);

  useEffect(() => {
    const updateHeight = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 640) {
        setDynamicHeight('250px');
      } else if (screenWidth >= 640 && screenWidth < 768) {
        setDynamicHeight('300px');
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        setDynamicHeight('350px');
      } else if (screenWidth >= 1024 && screenWidth < 1280) {
        setDynamicHeight('400px');
      } else {
        setDynamicHeight('405px');
      }
    };

    window.addEventListener('resize', updateHeight);
    updateHeight();

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  useEffect(() => {
    const fetchSliderDetails = async () => {
      try {
        const imageResponse = await axios.get('https://back.zubilaw.com/SliderLawyer/listImages');
        const textResponse = await axios.get('https://back.zubilaw.com/SliderLawyer/listTexts');
        setImages(imageResponse.data.data || []);
        setTexts(textResponse.data.data || []);
      } catch (error) {
        console.error('Failed to fetch slider details', error);
      }
    };

    fetchSliderDetails();
  }, []);

  const settings = {
    modules: [Navigation, Autoplay],
    spaceBetween: 0,
    slidesPerView: 1,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    loop: true,
    speed: 2000,
    navigation: false,
  };

  const nextSlide = () => {
    if (sliderRef.current && sliderRef.current.swiper) {
      sliderRef.current.swiper.slideNext();
    }
  };

  const prevSlide = () => {
    if (sliderRef.current && sliderRef.current.swiper) {
      sliderRef.current.swiper.slidePrev();
    }
  };

  return (
    <>
      <div
        className="relative flex justify-between items-center mx-auto xl:max-w-7xl"
        style={{
          height: dynamicHeight,
          overflow: 'hidden',
          backgroundColor: 'white',
          zIndex: 1,
        }}
      >
        {images.length > 0 && (
          <Swiper
            ref={sliderRef}
            {...settings}
            className="w-full h-full custom-carousel"
          >
            {images.map((image, index) => (
              <SwiperSlide key={index} style={{ height: dynamicHeight }}>
                <img
                  src={`https://back.zubilaw.com/${image.slider}`}
                  alt={`Slide ${index + 1}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    zIndex: -1,
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        <div className="absolute z-10 flex flex-col justify-center bottom-4 right-5 md:bottom-10 md:right-20  max-w-md bg-gray-700 bg-opacity-50 p-2 md:p-5 xl:p-6">
          <h1 className="text-sm md:text-lg lg:text-3xl font-semibold text-white" style={{ fontFamily: 'Arial' }}>
            {Departments}
          </h1>
        </div>

        {/* Navigation Buttons */}
        {images.length > 1 && (
          <>
            <button
              onClick={prevSlide}
              className="absolute left-2 sm:left-4 md:left-7 lg:left-10 xl:left-12 text-xl md:text-3xl lg:text-xl xl:text-4xl z-20"
              style={{
                top: '50%',
                transform: 'translateY(-50%)',
                color: '#ffffff',
                textShadow: '0px 0px 8px rgba(0, 0, 0, 0.8)',
                zIndex: 10,
              }}
            >
              &#10094;
            </button>
            <button
              onClick={nextSlide}
              className="absolute right-2 sm:right-4 md:right-7 lg:right-10 xl:right-12 text-xl md:text-3xl lg:text-xl xl:text-4xl z-20"
              style={{
                top: '50%',
                transform: 'translateY(-50%)',
                color: '#ffffff',
                textShadow: '0px 0px 8px rgba(0, 0, 0, 0.8)',
                zIndex: 10,
              }}
            >
              &#10095;
            </button>
          </>
        )}
      </div>
      <style jsx>{`
        .swiper-button-next, .swiper-button-prev {
          color: #ffffff;
          text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
          font-size: 24px;
        }
      `}</style>
    </>
  );
};

export default SliderDetailedAssociates;
