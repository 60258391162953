import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { Navigation, Autoplay } from 'swiper/modules';

const SliderComponent = () => {
  const navigate = useNavigate();
  const [dynamicHeight, setDynamicHeight] = useState('350px');
  const [images, setImages] = useState([]);
  const [title, setTitle] = useState('');
  const [learnMoreData, setLearnMoreData] = useState({ name: '', link: '' });
  const [letsWorkText, setLetsWorkText] = useState('');
  const [togetherText, setTogetherText] = useState('');
  const [contactUsText, setContactUsText] = useState('Loading...');
  const [contactUsLink, setContactUsLink] = useState({ name: '', link: '' });
  const sliderRef = useRef(null);

  useEffect(() => {
    const updateHeight = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 640) {
        setDynamicHeight('250px');
      } else if (screenWidth >= 640 && screenWidth < 768) {
        setDynamicHeight('300px');
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        setDynamicHeight('350px');
      } else if (screenWidth >= 1024 && screenWidth < 1280) {
        setDynamicHeight('400px');
      } else {
        setDynamicHeight('405px');
      }
    };

    window.addEventListener('resize', updateHeight);
    updateHeight();

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  useEffect(() => {
    const fetchSliderDetails = async () => {
      try {
        const response = await axios.get('https://back.zubilaw.com/slider/list');
        console.log('Slider Details Response:', response.data);

        if (response.data && response.data.data && response.data.data.length > 0) {
          const sliderImages = response.data.data.map(slider => {
            if (slider.slider) {
              return `https://back.zubilaw.com/${slider.slider}`;
            } else {
              console.error('Slider image path is undefined for a slider:', slider);
              return 'fallback-image-path.jpg';
            }
          });
          setImages(sliderImages);
        } else {
          console.error('Unexpected response structure or no slider details:', response.data);
        }
      } catch (error) {
        console.error('Failed to fetch slider details', error);
      }
    };

    fetchSliderDetails();
  }, []);

  useEffect(() => {
    const fetchInfoSliderItems = async () => {
      try {
        const response = await axios.get('https://back.zubilaw.com/infoslider/list');
        if (response.data && response.data.success && response.data.data.length > 0) {
          setTitle(response.data.data[0].name);
        } else {
          console.log('No infoslider items found');
        }
      } catch (error) {
        console.error('Error fetching infoslider items:', error);
      }
    };

    fetchInfoSliderItems();
  }, []);

  useEffect(() => {
    const fetchLearnMoreItems = async () => {
      try {
        const response = await axios.get('https://back.zubilaw.com/learnmore/list');
        if (response.data && response.data.success && response.data.data.length > 0) {
          const { name, link } = response.data.data[0];
          setLearnMoreData({ name, link });
        } else {
          console.log('No learnmore items found');
        }
      } catch (error) {
        console.error('Error fetching learnmore items:', error);
      }
    };

    fetchLearnMoreItems();
  }, []);

  useEffect(() => {
    const fetchLetsWorkItems = async () => {
      try {
        const response = await axios.get('https://back.zubilaw.com/letswork/list');
        if (response.data && response.data.success && response.data.data.length > 0) {
          setLetsWorkText(response.data.data[0].name);
        } else {
          console.log('No letswork items found');
          setLetsWorkText("LET'S WORK");
        }
      } catch (error) {
        console.error('Error fetching letswork items:', error);
        setLetsWorkText("LET'S WORK");
      }
    };

    fetchLetsWorkItems();
  }, []);

  useEffect(() => {
    const fetchTogetherItems = async () => {
      try {
        const response = await axios.get('https://back.zubilaw.com/together/list');
        if (response.data && response.data.success && response.data.data.length > 0) {
          setTogetherText(response.data.data[0].name);
        } else {
          console.log('No together items found');
          setTogetherText('No data');
        }
      } catch (error) {
        console.error('Error fetching together items:', error);
        setTogetherText('Failed to load data');
      }
    };

    fetchTogetherItems();
  }, []);

  useEffect(() => {
    const fetchContactUsItems = async () => {
      try {
        const response = await axios.get('https://back.zubilaw.com/contactus/list');
        if (response.data && response.data.success && response.data.data.length > 0) {
          const { name, link } = response.data.data[0];
          setContactUsText(name);
          setContactUsLink(link);
        } else {
          console.log('No contactus items found');
          setContactUsText('Contact Us');
        }
      } catch (error) {
        console.error('Error fetching contactus items:', error);
        setContactUsText('Contact Us');
      }
    };

    fetchContactUsItems();
  }, []);

  const settings = {
    modules: [Navigation, Autoplay],
    spaceBetween: 0,
    slidesPerView: 1,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    loop: true,
    speed: 2000,
    navigation: false,
  };

  const handleLearnMoreClick = () => {
    if (learnMoreData.link) {
      navigate('/about-us/profile');
    }
  };

  const handleContactUsClick = () => {
    navigate('/contact-us');
  };

  const nextSlide = () => {
    if (sliderRef.current && sliderRef.current.swiper) {
      sliderRef.current.swiper.slideNext();
    }
  };

  const prevSlide = () => {
    if (sliderRef.current && sliderRef.current.swiper) {
      sliderRef.current.swiper.slidePrev();
    }
  };

  return (
    <>
      <div
        className="relative flex justify-between items-center mx-auto xl:max-w-7xl"
        style={{
          height: dynamicHeight,
          overflow: 'hidden',
          backgroundColor: 'white',
          zIndex: 1,
        }}
      >
        {images.length > 0 && (
          <Swiper
            ref={sliderRef}
            {...settings}
            className="w-full h-full custom-carousel"
          >
            {images.map((image, index) => (
              <SwiperSlide key={index} style={{ height: dynamicHeight }}>
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    zIndex: -1,
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        {/* Left Side */}
        <div className="absolute z-10 flex flex-col justify-center ml-12 md:ml-20 lg:ml-36 max-w-md bg-gray-700 bg-opacity-50 p-2 md:p-5 xl:p-8">
          <h1 className="text-sm md:text-lg lg:text-3xl font-semibold text-white" style={{ fontFamily: 'Arial' }}>
            {title || 'Loading...'}
          </h1>
          <button
            onClick={handleLearnMoreClick}
            className="mt-4 bg-white text-black py-1 px-3 sm:pr-20 md:py-2 md:px-4 uppercase font-semibold text-xs md:text-sm ml-0 mr-40 sm:mr-60 md:mr-60 lg:mr-60"
            style={{ fontFamily: 'Arial' }}
          >
            {learnMoreData.name || 'Loading...'}
          </button>
        </div>

        {/* Right Side */}
        <div className="absolute z-10 hidden lg:block p-10 bg-white text-gray-800 right-0 mt-80" style={{ width: '550px' }}>
          <h2 className="text-2xl" style={{ fontFamily: '"Times New Roman", Times, serif' }}>
            {letsWorkText}
          </h2>
          <h2 className="text-2xl" style={{ fontFamily: '"Times New Roman", Times, serif' }}>
            {togetherText}
          </h2>
          <button
            onClick={handleContactUsClick}
            className="text-[#777777] text-xs font-bold uppercase tracking-wider relative"
          >
            {contactUsText}
            <span className="absolute left-24 bottom-2 bg-[#777777] h-0.5 w-10" />
          </button>
        </div>

        {/* Navigation Buttons */}
        {images.length > 1 && (
          <>
            <button
              onClick={prevSlide}
              className="absolute left-2 sm:left-4 md:left-7 lg:left-10 xl:left-12 text-xl md:text-3xl lg:text-xl xl:text-4xl z-20"
              style={{
                top: '50%',
                transform: 'translateY(-50%)',
                color: '#ffffff',
                textShadow: '0px 0px 8px rgba(0, 0, 0, 0.8)',
                zIndex: 10,
              }}
            >
              &#10094;
            </button>
            <button
              onClick={nextSlide}
              className="absolute right-2 sm:right-4 md:right-7 lg:right-10 xl:right-12 text-xl md:text-3xl lg:text-xl xl:text-4xl z-20"
              style={{
                top: '50%',
                transform: 'translateY(-50%)',
                color: '#ffffff',
                textShadow: '0px 0px 8px rgba(0, 0, 0, 0.8)',
                zIndex: 10,
              }}
            >
              &#10095;
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default SliderComponent;
