import React, { useState, useEffect } from 'react';
import axios from 'axios';
import linkedin from '../Images/linkedin.png';

const Footer = () => {
  const [logoDetails, setLogoDetails] = useState(null);
  const [footerItems, setFooterItems] = useState([]);
  const [copyrightTitle, setCopyrightTitle] = useState('');

  useEffect(() => {
    // Fetch logo details
    const fetchLogoDetails = async () => {
      try {
        const logoResponse = await axios.get('https://back.zubilaw.com/logo/list');
        if (logoResponse.data.success) {
          setLogoDetails(logoResponse.data.data[0].logo);
        }
      } catch (error) {
        console.error('Error fetching logo details:', error);
      }
    };

    // Fetch footer items
    const fetchFooterItems = async () => {
      try {
        const response = await axios.get('https://back.zubilaw.com/footer/list');
        if (response.data.success) {
          // Sort items by position just in case they're not in order
          const sortedItems = response.data.data.sort((a, b) => a.position - b.position);
          setFooterItems(sortedItems);
        }
      } catch (error) {
        console.error('Error fetching footer items:', error);
      }
    };

    const fetchCopyrightTitle = async () => {
      try {
        const copyrightResponse = await axios.get('https://back.zubilaw.com/copyright/list');
        if (copyrightResponse.data.success && copyrightResponse.data.data.length > 0) {
          setCopyrightTitle(copyrightResponse.data.data[0].title);
        }
      } catch (error) {
        console.error('Error fetching copyright title:', error);
      }
    };

    fetchLogoDetails();
    fetchFooterItems();
    fetchCopyrightTitle();
  }, []);

  const aboveLineItems = footerItems.filter(item => item.position <= 8);
  const belowLineItems = footerItems.filter(item => item.position);

  return (
    <footer className="mx-auto xl:max-w-7xl py-7 pt-7" style={{ backgroundColor: '#f5f5f5' }}>
   

        <div className="flex flex-col md:flex-row justify-between items-center mt-7 w-full px-10 lg:px-0">
        <p className="text-sm  text-gray-600 mb-4 md:mb-0  " style={{ fontSize: '13px' }}>
            © 2013 - {new Date().getFullYear()} {copyrightTitle}
          </p>
          <a href="/disclaimer" className="text-gray-600 hover:text-[#777777]  lg:w-1/3" style={{fontSize:'13px'}}>
            DISCLAIMER
          </a>
          <div  className="flex flex-row mt-7 md:mt-0 ">
          {footerItems.map((item, index) => (
  <a
    key={index}
    href={item.link}
    target="_blank"
    rel="noopener noreferrer"
    className={`h-7 mx-auto md:mx-0 ${index !== 0 ? 'ml-2 md:ml-2 xl:ml-4' : ''}`}
  >
   <img
  src={`https://back.zubilaw.com/${item.image}`}
  alt={item.name}
  className="h-7 w-7  md:mx-0"
/>

  </a>
))}

</div>
        </div>
      
    </footer>
  );
};

export default Footer;
