import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';


const styles = `
    @keyframes scaleUp {
      from {
        transform: scale(1);
      }
      to {
        transform: scale(1.05);
      }
    }

    .hover-card {
      transition: transform 0.3s ease;
    }

    .hover-card:hover {
      animation: scaleUp 0.3s ease forwards;
    }
  `;

const NewsCard = ({ image, headline, description, onClick }) => {
  const truncateDescription = (desc) => {
    const doc = new DOMParser().parseFromString(desc, 'text/html');
    const text = doc.body.textContent || "";
    const words = text.split(' ');
    return words.slice(0, 10).join(' ') + (words.length > 10 ? '...' : '');
  };


  return (
    <div className=" hover-card " onClick={onClick} style={{ cursor: 'pointer', fontFamily: 'Arial' }}>
      <div className="rounded-lg overflow-hidden h-64 w-64 shadow-lg flex items-center justify-center mb-5" style={{ borderRadius: '2.5rem' }}>
        <img className="max-w-full max-h-full" style={{ borderRadius: '2.5rem' }} src={image} alt="News" />
      </div>
      <div className="py-4 items-center">
        <div className="font-semibold text-sm mb-2 p-2 text-md md:text-lg mt-5 text-center">{headline}</div>
        <div className="text-gray-700 text-base text-center" dangerouslySetInnerHTML={{ __html: truncateDescription(description) }} />
      </div>
    </div>
  );
};

const NewsSection = () => {
  const navigate = useNavigate();
  const [sectionTitle, setSectionTitle] = useState('Loading...');
  const [newsSectionTitle, setNewsSectionTitle] = useState('Loading...');
  const [featureSections, setFeatureSections] = useState([]);

  useEffect(() => {
    const fetchSectionTitle = async () => {
      try {
        const response = await axios.get('https://back.zubilaw.com/latestposts/list');
        if (response.data && response.data.success && response.data.data.length > 0) {
          setSectionTitle(response.data.data[0].title);
        } else {
          console.log('Section title not found');
          setSectionTitle('Title not available');
        }
      } catch (error) {
        console.error('Error fetching section title:', error);
        setSectionTitle('Failed to load title');
      }
    };

    fetchSectionTitle();
  }, []);

  useEffect(() => {
    const fetchNewsSectionTitle = async () => {
      try {
        const response = await axios.get('https://back.zubilaw.com/latestnews/list');
        if (response.data && response.data.success && response.data.data.length > 0) {
          setNewsSectionTitle(response.data.data[0].title);
        } else {
          console.log('News section title not found');
          setNewsSectionTitle('Title not available');
        }
      } catch (error) {
        console.error('Error fetching news section title:', error);
        setNewsSectionTitle('Failed to load title');
      }
    };

    fetchNewsSectionTitle();
  }, []);

  useEffect(() => {
    const fetchFeatureSections = async () => {
      try {
        const response = await axios.get('https://back.zubilaw.com/featureSection/list');
        if (response.data && response.data.success) {
          setFeatureSections(response.data.data);
        } else {
          console.log('Feature sections not found');
        }
      } catch (error) {
        console.error('Error fetching feature sections:', error);
      }
    };

    fetchFeatureSections();
  }, []);

  const handleCardClick = (newsItem) => {
    navigate(`/news/${newsItem.id}`, { state: { imageSrc: newsItem.image, title: newsItem.title, description: newsItem.paragraph } });
  };

  const handleViewAllClick = () => {
    navigate('/news');
  };

  return (
    <div className="flex flex-col items-center mx-auto xl:max-w-7xl pt-20" style={{ backgroundColor: 'white' }}>
      <div className=""> {/* Center the container */}
        <div className="text-start mb-8 ml-7 md:ml-24 xl:ml-4">
          <h2 className="text-2xl font-semibold text-[#972a36]" style={{ fontFamily: 'Arial' }}>
            {sectionTitle}
          </h2>
          <div className="flex justify-start items-center">
            <h2 className="text-2xl font-semibold text-[#972a36]" style={{ fontFamily: 'Arial' }}>
              {newsSectionTitle}
            </h2>
            <span className="bg-[#777777] h-0.5 w-16 ml-4 mt-2" />
          </div>
        </div>

        <div className="max-w-5xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 w-full px-8 xl:px-4 gap-14">
          {featureSections.slice(0, 3).map((section, index) => (
            <div key={index} className="flex flex-col items-center px-0 md:px-10 xl:px-0">
              <NewsCard 
                image={`https://back.zubilaw.com/${section.image}`} // Prepend the base URL to the image path
                headline={section.title} 
                description={section.paragraph} 
                onClick={() => handleCardClick(section)} 
              />
            </div>
          ))}
        </div>
        <div className="flex flex-wrap justify-center mt-8">
          <button
            className="bg-[#972a36] text-white py-2 px-4 rounded"
            onClick={handleViewAllClick}
          >
            View All
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewsSection;
