import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from '../Components/SliderContactUs';


const ContactUs = () => {
    const [isHovered, setIsHovered] = useState(false);
    const [contactInfo, setContactInfo] = useState({});
    const [images, setImages] = useState([]); 

    useEffect(() => {
      const fetchSliderDetails = async () => {
        try {
          const response = await axios.get('https://back.zubilaw.com/slider/list');
          if (response.data && response.data.data && response.data.data.length > 0) {
            const sliderImages = response.data.data.map(slider => slider.slider || 'fallback-image-path.jpg');
            setImages(sliderImages);
          } else {
            console.error('Unexpected response structure or no slider details:', response.data);
          }
        } catch (error) {
          console.error('Failed to fetch slider details', error);
        }
      };
    
      fetchSliderDetails();
    }, []);

    useEffect(() => {
      // Replace 'your-api-endpoint' with the actual endpoint from which you are fetching contact data
      const fetchContactInfo = async () => {
          try {
              const response = await axios.get('https://back.zubilaw.com/contactusdata/list');
              setContactInfo(response.data.data[0]); // Assuming the API returns an array and you're interested in the first item
          } catch (error) {
              console.error('Failed to fetch contact info', error);
          }
      };

      fetchContactInfo();
  }, []);


  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="container bg-[#777777] items-center   mx-auto xl:max-w-7xl w-full px-5 md:px-0 xl:px-0 pb-10" style={{ backgroundColor: 'white' }}>
 <Slider Contact="Contact us" />
    <div className="flex flex-col  justify-between">

      <div className="flex justify-center  mt-14 ">
        
        <div className="w-full max-w-6xl px-4 xl:px-12 ml-0 xl:ml-10">
          <div className="flex flex-col xl:flex-row justify-center items-start space-y-10 xl:space-y-0 xl:space-x-10 bg-white p-8 ">
            {/* Form Section */}
            <div className="w-full xl:w-1/2">
            <h2 className="text-4xl font-semibold mb-6" style={{ fontFamily: 'Arial' }}>{contactInfo.formtitle}</h2>

              <form>
                {/* Name Input */}
                <div className="mb-6">
                  <input className="appearance-none block w-full border border-gray-300 py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-gray-500" type="text" placeholder="Type your name.." style={{ fontFamily: 'Arial' }} />
                </div>
                {/* Email Input */}
                <div className="mb-6">
                  <input className="appearance-none block w-full border border-gray-300 py-3 px-4 leading-tight focus:outline-none focus:border-gray-500" type="email" placeholder="Type your e-mail.." style={{ fontFamily: 'Arial' }}/>
                </div>
                {/* Message Textarea */}
                <div className="mb-6 " >
                  <textarea className="appearance-none block w-full  border border-gray-300 py-3 px-4 leading-tight focus:outline-none focus:border-gray-500" placeholder="Your message (optional)" style={{height:'380px', fontFamily: 'Arial'}}></textarea>
                </div>
                {/* Submit Button */}
                <div className="flex justify-center">
      <button
        className={`border border-gray-500 text-gray-600 hover:text-white py-2 px-6 transition-colors duration-300 ${
          isHovered ? 'bg-[#840626] text-white' : ''
        }`}
        style={{ fontFamily: 'Arial' }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        SEND MESSAGE
      </button>
    </div>
              </form>
            </div>

            {/* Image and Office Info Section */}
            <div className="w-full xl:w-1/2 mt-12 " >
              <img src={`https://back.zubilaw.com/${contactInfo.image}`} alt="Office" className=" w-full  h-80 mx-auto object-cover "  />
              <div className="text-gray-800 mt-6">
                <h3 className="text-2xl font-semibold mb-3" style={{ fontFamily: 'Arial' }}>{contactInfo.title}</h3>
                <p style={{ fontFamily: 'Arial' }}>{contactInfo.address} </p>
                <p className="my-1" style={{ fontFamily: 'Arial' }}>{contactInfo.mobile}</p>
                <p style={{ fontFamily: 'Arial' }}>{contactInfo.email}</p>
                <p style={{ fontFamily: 'Arial' }}>{contactInfo.fax}</p>
                <h3 className="text-2xl font-semibold mb-3 mt-10" style={{ fontFamily: 'Arial' }}>{contactInfo.mailtitle}</h3>
                <p style={{ fontFamily: 'Arial' }}>{contactInfo.name}</p>
                <p className="my-1" style={{ fontFamily: 'Arial' }}>{contactInfo.mail}</p>
                <p style={{ fontFamily: 'Arial' }}>{contactInfo.mailaddress}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </div>
    <div className="w-full border-t border-gray-300 mb-5 mt-10 mx-auto" style={{ maxWidth: '50%' }}></div>
    </div>

  );
};

export default ContactUs;
